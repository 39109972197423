<template>
  <div style="width: 100%" v-loading="loading" class="recharge-detail">
    <el-form
      ref="postForm"
      label-position="right"
      label-width="100px"
      :model="tableData"
      size="medium"
      v-if="$props.row.orderFlowType == 1">
      <el-form-item label="充值驾校">
        {{ $props.row.drvSchoolForShort }}
      </el-form-item>
      <el-form-item label="充值金额">
        {{ $props.row.orderFlowAmount }}
      </el-form-item>
      <el-form-item label="业务编号">
        {{ tableData.businessNo }}
      </el-form-item>
      <el-form-item label="交易流水号">
        {{ tableData.payNo }}
      </el-form-item>
      <el-form-item label="付款人">
        {{ tableData.payer }}
      </el-form-item>
      <el-form-item label="付款开户行">
        {{ tableData.bank }}
      </el-form-item>
      <el-form-item label="充值凭证">
        <el-image
          style="max-width: 100%"
          fit="contain"
          :src="$dict.SETTING.IMG_URL + tableData.voucherPath"
          :preview-src-list="[$dict.SETTING.IMG_URL + tableData.voucherPath]">
        </el-image>
      </el-form-item>
    </el-form>

    <!-- 结算需显示 -->
    <el-descriptions :column="2" border v-else>
      <el-descriptions-item label="姓名">{{ tableData.studentName }}</el-descriptions-item>
      <!-- <el-descriptions-item label="会员服务单号">{{ tableData.policyNo }}</el-descriptions-item> -->
      <el-descriptions-item label="身份证号">{{ tableData.studentIdCard }}</el-descriptions-item>
      <el-descriptions-item label="会员服务金额">{{ tableData.orderFlowAmount }}</el-descriptions-item>
      <el-descriptions-item label="驾照类型">{{ tableData.vehicleType }}</el-descriptions-item>
      <el-descriptions-item label="订单时间">{{ $utils.formatDateFull(tableData.creationTime) }}</el-descriptions-item>
    </el-descriptions>

    <div class="admin-dialog-btns" style="margin-top: 15px">
      <el-button
        @click="
          () => {
            this.$emit('end');
          }
        "
        >关闭
      </el-button>
    </div>
  </div>
</template>

<script>
import {rechargeFlowGetById} from '@/api/index.js';
export default {
  props: {
    row: Object,
  },
  data() {
    return {
      loading: false,
      tableData: {},
    };
  },
  created() {
    this.loading = true;
    rechargeFlowGetById({
      orderFlowId: this.$props.row.orderFlowId,
      orderFlowType: this.$props.row.orderFlowType,
    })
      .then((res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {},
};
</script>

<style></style>
